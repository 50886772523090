/* global styles */

* {
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 0 auto;
}

main {
  display: flex;
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

a { 
  color: #1175A7;
  text-decoration: none;
  font-weight: 400;
}

a.link-light-weight {
  font-weight: 400;
}

.text-gray {
  color: #8e8e93;
}